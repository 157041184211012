/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "~material-design-icons/iconfont/material-icons.css";
@import "~@swimlane/ngx-datatable/index.css";
@import "~@swimlane/ngx-datatable/themes/material.css";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~material-design-icons/iconfont/material-icons.css";
/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
/* FIX FOR NG HIDE*/

// @import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import "~saturn-datepicker/bundle.css";

[hidden] {
    display: none !important;
}

html,
body {
    height: 100%;
    margin: 0px;
    padding: 0px;
}

* {
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
}

.new-buttom {
    background-color: #1cac67 !important;
    color: white !important;
}

.action-button {
    color: #1cac67 !important;
}

.datatable {
    box-shadow: none !important;
    border-radius: 3% !important;
}

.dialog-actions {
    margin-bottom: 0px !important;
    float: right !important;

    .button {
        margin-left: 10px !important;
    }
}

.action-on-table {
    margin-top: -40px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@import "@angular/material/theming";
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
    $input: mat.define-typography-level(15px, 1.125, 600),
);
// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
// @include mat-base-typography($custom-typography);
@include mat.all-component-typographies($custom-typography);

.mat-form-field-can-float.mat-form-field-should-float {
    .mat-form-field-label {
        transform: translateY(-1.59375em) scale(0.95) !important;
        top: 1.5em !important;
        color: rgb(33, 86, 58) !important;
    }
}

.mat-focused .mat-form-field-ripple {
    background-color: rgb(33, 86, 58) !important;
}

.mat-select-arrow {
    color: rgb(33, 86, 58) !important;
}

.mat-select-trigger {
    color: rgb(33, 86, 58) !important;
}

.mat-ink-bar {
    background-color: rgb(33, 86, 58) !important;
}

.mat-checkbox-checked .mat-checkbox-background {
    background-color: rgb(33, 86, 58) !important;
}

.align {
    text-align: -webkit-center;
}

.bold-font {
    text-align: center;
    font-weight: bold;
}

.center-block {
    float: none;
}

.col-center {
    float: none;
    margin: 0 auto;
}

.card-header-custom {
    padding-top: 1px;
    margin-bottom: -18px;
}

.focused-content {
    margin-top: 70px;
    padding: 120px 0;
    min-height: calc(100vh - 123px);
    margin: 0 auto;
    width: 93%;
}

.green-progress .mat-progress-bar-fill::after {
    background-color: #1cac67 !important;
}

:host ::ng-deep .custom-spinner circle {
    stroke: white;
}
